import React from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { downloadExcel } from "react-export-table-to-excel";
import moment from "moment";
import { formatPhoneNumber } from "../../../../utils";

const PlayerInfoModal = ({ show, onHide, playerInfo, isMembership,playerstats }) => {
  
  
  const handleSaveExcel = async () => {
    // Define the headers in a single row format
    const header = ['Name', 'Surname', 'Nickname', 'Street and number', 'ZIP and City', 'Birthdate', 'E-Mail Address', 'Phone Number', 'First Registration', 'Membership', 'Registration with check-in', 'Registration without check-in', 'Last Registered', 'Status'];
  
    // Create the body as a single row with player data
    const body = [
      [
        playerInfo?.firstname,
        playerInfo?.lastname,
        playerInfo?.nickname,
        playerInfo?.street,
        `${playerInfo?.zipcode} ${playerInfo?.city}`,
        playerInfo?.dob ? moment(playerInfo?.dob).format("DD.MM.YYYY") : "",
        playerInfo?.email,
        formatPhoneNumber(playerInfo?.phonecode, playerInfo?.phonenumber),
        playerInfo?.firstRegistrationDate
          ? moment(playerInfo?.firstRegistrationDate).format("DD.MM.YYYY")
          : "Before 04.2024",
        !isMembership
          ? "Not active"
          : playerInfo?.membership && playerInfo?.membership !== "-"
          ? moment(playerInfo?.membership).format("DD.MM.YYYY")
          : "-",
        playerstats && playerstats[0]?.with_checkin,
        playerstats && playerstats[0]?.without_checkin,
        playerstats && playerstats[0]?.last,
        playerstats && playerstats[0].is_suspend === false ? 'Active' : 'Suspended',
      ]
    ];
  
    // Export to Excel with the adjusted layout
    downloadExcel({
      fileName: "Check-Raise",
      sheet: "PlayerInfo",
      tablePayload: {
        header,
        body,
      },
    });
  };

  return (
    <>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>
            {playerInfo?.firstname} {playerInfo?.lastname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Label>Name : {playerInfo?.firstname} </Form.Label>
            <Form.Label>Surname : {playerInfo?.lastname}</Form.Label>
            <Form.Label>Nickname : {playerInfo?.nickname}</Form.Label>
            <Form.Label>Street and number : {playerInfo?.street}</Form.Label>
            <Form.Label>
              ZIP and City : {playerInfo?.zipcode} {playerInfo?.city}
            </Form.Label>
            <Form.Label>
              Birthdate :{" "}
              {playerInfo?.dob
                ? moment(playerInfo?.dob).format("DD.MM.YYYY")
                : ""}
            </Form.Label>
            <Form.Label>
              E-Mail Address :{" "}
              <a className="text-white" href={`mailto:${playerInfo?.email}`}>
                {playerInfo?.email}
              </a>              
            </Form.Label>            
            <Form.Label>
              Phone Number :{" "}
              {formatPhoneNumber(playerInfo?.phonecode, playerInfo?.phonenumber)}
            </Form.Label>
            <Form.Label>
              First Registration:{" "}
              {playerInfo?.firstRegistrationDate
                ? moment(playerInfo?.firstRegistrationDate).format("DD.MM.YYYY")
                : "Before 04.2024"}
            </Form.Label>
            <Form.Label>
              Membership:{" "}
              {!isMembership
                ? "Not active"
                : playerInfo?.membership && playerInfo?.membership !== "-"
                  ? moment(playerInfo?.membership).format("DD.MM.YYYY")
                  : "-"}
            </Form.Label>
            <Form.Label>Registration with check-in : {playerstats && playerstats[0]?.with_checkin} </Form.Label>
            <Form.Label>Registration without check-in : {playerstats && playerstats[0]?.without_checkin} </Form.Label>
            <Form.Label>Last Registered : {playerstats && playerstats[0]?.last} </Form.Label>
            <Form.Label>Status: {playerstats && playerstats[0].is_suspend === false ? 'Active' : 'Suspended'}</Form.Label>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" download onClick={handleSaveExcel}>
            Save Excel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlayerInfoModal;
